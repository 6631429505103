import { TailwindColorGroup } from 'tailwindcss/tailwind-config'
import {
  amber,
  blue,
  green,
  lime,
  orange,
  red,
  sky,
  teal,
  violet,
  yellow,
} from 'tailwindcss/colors'

export type HostType = 'sellematics' | 'sellmonitor' | 'uzum' | 'sellscreen' | 'psb'

export type YandexMetrica = {
  id: number
  options: { [key: string]: any }
}

export type FacbookMetrics = {
  id: number
}

export type ContactInformation = {
  phone?: string
  mail?: string
  telegram?: string
  instagram?: string
  whatsapp?: string
  wechat?: string
  youtube?: string
}

export type Theme = {
  hostType: HostType
  apiURL: string
  title: string
  releaseDate: number
  themeColors: TailwindColorGroup
  colorSeries: TailwindColorGroup[]
  logoDark: string
  logoFixed: string
  logoWhite: string
  chartFonts: string[]
  yandexMetrica?: YandexMetrica
  facebookMetrics?: FacbookMetrics
  contactInformation: ContactInformation
}

const SELLEMATICS: Theme = {
  hostType: 'sellematics',
  apiURL: process.env.NEXT_PUBLIC_SELLEMATICS_API_BASE_URL!,
  title: 'Sellematics',
  releaseDate: 2019,
  themeColors: orange,
  colorSeries: [orange, teal, yellow, sky, red, violet, amber, blue, green],
  logoDark: '/new/images/logo-dark.svg',
  logoFixed: '/new/images/logo-fixed.svg',
  logoWhite: '/new/images/logo-white.svg',
  chartFonts: ['Roboto Flex', 'sans-serif'],
  yandexMetrica: {
    id: 64582036,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    },
  },
  contactInformation: {
    phone: '+74954141011',
    mail: 'info@sellematics.com',
    whatsapp: '+79899716847',
  },
}

const SELLMONITOR: Theme = {
  hostType: 'sellmonitor',
  apiURL: process.env.NEXT_PUBLIC_SELLMONITOR_API_BASE_URL!,
  title: 'Sellmonitor',
  releaseDate: 2019,
  themeColors: blue,
  colorSeries: [blue, green, orange, lime, yellow, sky, red, violet, amber],
  logoDark: '/theme/sellmonitor/images/logo-dark.svg',
  logoFixed: '/theme/sellmonitor/images/logo-fixed.svg',
  logoWhite: '/theme/sellmonitor/images/logo-white.svg',
  chartFonts: ['Roboto Flex', 'sans-serif'],
  yandexMetrica: {
    id: 76206847,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  },
  contactInformation: {
    phone: '+74954141011',
    mail: 'info@sellmonitor.com',
    telegram: 'SellmonitorHELP_bot',
    instagram: 'sellmonitor_com',
    youtube: 'Sellmonitor',
  },
}

const UZUM: Theme = {
  hostType: 'uzum',
  apiURL: process.env.NEXT_PUBLIC_UZUM_API_BASE_URL!,
  title: 'Sellmonitor',
  releaseDate: 2019,
  themeColors: blue,
  colorSeries: [blue, green, orange, lime, yellow, sky, red, violet, amber],
  logoDark: '/theme/sellmonitor/images/logo-dark.svg',
  logoFixed: '/theme/sellmonitor/images/logo-fixed.svg',
  logoWhite: '/theme/sellmonitor/images/logo-white.svg',
  chartFonts: ['Roboto Flex', 'sans-serif'],
  yandexMetrica: {
    id: 76206847,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  },
  contactInformation: {
    phone: '+74954141011',
    mail: 'info@sellmonitor.com',
    telegram: 'SellmonitorHELP_bot',
    instagram: 'sellmonitor_com',
    youtube: 'Sellmonitor',
  },
}

const SELLSCREEN: Theme = {
  hostType: 'sellscreen',
  apiURL: process.env.NEXT_PUBLIC_SELLSCREEN_API_BASE_URL!,
  title: 'Sellscreen',
  releaseDate: 2022,
  themeColors: blue,
  colorSeries: [blue, green, orange, lime, yellow, sky, red, violet, amber],
  logoDark: '/next/sellscreen/images/logo-dark.svg',
  logoFixed: '/next/sellscreen/images/logo-fixed.svg',
  logoWhite: '',
  chartFonts: ['Roboto Flex', 'sans-serif'],
  yandexMetrica: {
    id: 89200218,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  },
  facebookMetrics: {
    id: 1319899998823877,
  },
  contactInformation: {
    wechat: 'saioukf',
  },
}

const PSB: Theme = {
  hostType: 'psb',
  apiURL: process.env.NEXT_PUBLIC_PSB_API_BASE_URL!,
  title: 'ПСБ Аналитика на маркетплейсах',
  releaseDate: 2025,
  themeColors: {
    50: '#EEEFFB',
    100: '#DEDEF7',
    200: '#BDBEF0',
    300: '#9C9DE8',
    400: '#7A7CE0',
    500: '#595BD9',
    600: '#383AD1',
    700: '#2527A2',
    800: '#191A6C',
    900: '#0C0D36',
    950: '#07071D',
  },
  colorSeries: [blue, green, orange, lime, yellow, sky, red, violet, amber],
  logoDark: '/next/psb/images/logo-dark.svg',
  logoFixed: '/next/psb/images/logo-fixed.svg',
  logoWhite: '/next/psb/images/logo-white.svg',
  chartFonts: ['Roboto Flex', 'sans-serif'],
  contactInformation: {},
}

const DEFAULT: Theme = SELLMONITOR

export const Themes = { SELLEMATICS, SELLMONITOR, SELLSCREEN, UZUM, PSB, DEFAULT }
