import Head from 'next/head'
import Link from 'components/base/Link'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import Image from 'next/image'
import backgroundImage from '@/public/next/img/landing/background-auth.jpg'

type Props = {
  pageName: string
}

const AuthLayout: FC<Props> = ({ pageName, children }) => {
  const ctx = useUserContext()

  return (
    <>
      <Head>
        <title>{`${pageName} | ${ctx.theme.title}`}</title>
      </Head>
      {ctx.theme.hostType !== 'psb' && ctx.theme.hostType !== 'sellematics' && (
        <div className="fixed inset-0 w-full h-full">
          <div className="absolute rotate-45 top-1/3 -left-[10vmax] size-0 [border-width:0_14vmax_21vmax_14vmax;] [border-color:transparent_transparent_theme(colors.violet.300)_transparent;] rounded-full bg-gradient-to-r from-violet-300 to-purple-400 opacity-50 blur-lg"></div>
          <div className="rotate-12 absolute top-3/4 -right-[3.75vmax] size-[22.5vmax] bg-gradient-to-r from-yellow-300 to-lime-300 opacity-50 blur-lg"></div>
          <div className="absolute -top-[7.5vmax] right-[15vmax] size-[15vmax] rounded-full bg-gradient-to-r from-cyan-400 to-blue-300 opacity-50 blur-lg"></div>
        </div>
      )}
      <div className="relative flex justify-center min-h-screen max-w-[100vmax] md:px-12 lg:px-0 overflow-hidden">
        <div className="relative z-10 flex flex-col justify-center flex-1 px-4 pt-16 pb-24 md:flex-none md:px-28">
          <div className="w-full max-w-md mx-auto sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <Link href="/" aria-label="Home" className="flex justify-center">
              <img className="h-6" src={ctx.theme.logoDark} />
            </Link>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthLayout
