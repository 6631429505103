import { FC, PropsOf } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { CountryCode } from 'libphonenumber-js'
import { forwardRef, Ref, useMemo, useState } from 'react'
import { getCountries } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import Input from './Input'
import PhoneNumberCountrySelectButton from './PhoneNumberCountrySelectButton'
import Select from './Select'
import { FieldValues } from 'react-hook-form'
import { match } from 'ts-pattern'

type Props = {
  hasError?: boolean
}

export const getFlagEmoji = (countryCode: CountryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

const PhoneNumberInput = <FormValues extends FieldValues>(
  {
    name,
    control,
    hasError,
    ...rest
  }: Props & PropsOf<typeof PhoneInput<PropsOf<typeof Input>, FormValues>>,
  ref: Ref<HTMLInputElement>
) => {
  const ctx = useUserContext()
  const { t, lang, format } = useIntl()

  return (
    <PhoneInput<PropsOf<typeof Input>, FormValues>
      ref={ref}
      inputComponent={Input as () => JSX.Element}
      hasError={hasError}
      name={name!}
      control={control!}
      {...rest}
    />
  )
}

export default forwardRef(PhoneNumberInput as any) as typeof PhoneNumberInput
