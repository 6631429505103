import { ArrowNarrowLeftIcon } from '@heroicons/react/solid'
import Button from 'components/base/Button'
import FormItem from 'components/base/FormItem'
import Input from 'components/base/Input'
import Spinner from 'components/base/Spinner'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useTrackGoal } from 'lib/metrics'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Updater } from 'use-immer'
import { P, match } from 'ts-pattern'
import {
  LoginFormData,
  ProcessState,
  RecoveryFormData,
  UserFormData,
} from 'components/auth/lib/types'
import cookieParser from 'cookie'
import { CheckIcon } from '@heroicons/react/outline'
import { amplitude } from 'components/app/Amplitude'
import { useStateFromLocalStorage } from 'lib/hooks'
import { useSetAtom } from 'jotai'
import { loginSignalAtom } from './lib/atoms'

type Props<T extends UserFormData | LoginFormData | RecoveryFormData> = {
  processState: ProcessState<T>
  setProcessState: Updater<ProcessState<T>>
  redirect?: string
}

const ConfirmationCodeForm = <T extends UserFormData | LoginFormData | RecoveryFormData>({
  processState,
  setProcessState,
  redirect,
}: Props<T>) => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  // const { executeRecaptcha, loaded: recaptchaLoaded } = useReCaptcha()
  const trackGoal = useTrackGoal()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    resetField,
    setValue,
  } = useForm<{ code: string }>({ mode: 'onTouched' })
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [retryButtonTimeout, setRetryButtonTimeout] = useState(60)
  const [retryButtonUsed, setRetryButtonUsed] = useState(false)
  const router = useRouter()

  const setLoginSignal = useSetAtom(loginSignalAtom)

  const statusMessages = {
    AuthCodeInvalid: t`registration.invalid_code`,
    AuthCodeMaxAttempts: t`registration.code_max_attempts`,
    AuthCodeExpired: t`registration.code_expired`,
    TwoFactorNotInProgress: t`registration.something_went_wrong`,
    Unknown: t`registration.something_went_wrong`,
  } as const

  const handleError = useCallback(
    (status: keyof typeof statusMessages) => {
      setError('code', { type: 'validate', message: statusMessages[status] })
      resetField('code', { keepError: true })
    },
    [setError, resetField]
  )

  useEffect(() => {
    const decrementTimer = () => {
      setRetryButtonTimeout((t) => {
        if (retryButtonTimeout <= 0) return t
        return t - 1
      })
    }
    const interval = setInterval(decrementTimer, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    if ('credentials' in navigator && 'OTPCredential' in window) {
      navigator.credentials
        .get({ otp: { transport: ['sms'] }, signal: abortController.signal } as any)
        .then((otp) => {
          if (otp && 'code' in otp) {
            setValue('code', otp.code as string)
            handleSubmit(onCodeSubmit)()
          }
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            return
          }
        })
    }

    return () => abortController.abort()
  }, [])

  const onCodeSubmit = useCallback(async (data: { code: string }) => {
    setSubmitting(true)
    if (processState.type === 'signup') {
      const payload = processState.data! as UserFormData
      const response = await ctx.registerUser({
        authCode: data.code,
        phone: payload.phone,
      })

      setSubmitting(false)

      match(response)
        .with({ status: 'UserRegistered' }, (response) => {
          setSuccess(true)
          trackGoal('registration-finished')
          amplitude.track('Signup Completed')
          if (ctx.theme.hostType === 'sellmonitor') {
            const cookies = cookieParser.parse(document.cookie)
            if (cookies['utm_source'] === 'actionpay' && process.env.NODE_ENV === 'production') {
              ctx.trackActionpayActionClient('confirmed_signup', `${response.userId}_reg`, 0)
            }
          }
          router.push('/subscription/')
        })
        .with(
          {
            status: P.select(P.union('AuthCodeInvalid', 'AuthCodeMaxAttempts', 'AuthCodeExpired')),
          },
          handleError
        )
        .otherwise(() => handleError('Unknown'))
    }
    if (processState.type === 'login') {
      const response = await ctx.logInSubmitAuthCode(data.code)

      setSubmitting(false)

      match(response)
        .with({ status: 'AuthSuccess' }, () => {
          setSuccess(true)
          setTimeout(() => {
            setLoginSignal((s) => !s)
          }, 5000)
          amplitude.track('Login Completed')
          router.push(redirect ?? '/products/list/')
        })
        .with(
          {
            status: P.select(
              P.union(
                'AuthCodeInvalid',
                'AuthCodeMaxAttempts',
                'AuthCodeExpired',
                'TwoFactorNotInProgress'
              )
            ),
          },
          handleError
        )
        .exhaustive()
    }
    if (processState.type === 'recovery') {
      const payload = processState.data! as RecoveryFormData
      const response = await ctx.recoveryResetPassword(payload.phone, data.code)

      setSubmitting(false)

      match(response)
        .with({ status: 'UserPasswordReset' }, (response) => {
          setSuccess(true)
          setProcessState((s: any) => {
            s.stage = 'success'
            s.username = response.username
            s.password = response.password
          })
        })
        .with(
          {
            status: P.select(P.union('AuthCodeInvalid', 'AuthCodeMaxAttempts', 'AuthCodeExpired')),
          },
          handleError
        )
        .exhaustive()
    }
  }, [])

  const { onChange, ...codeInputProps } = register('code')

  return (
    <>
      <div className="mt-20">
        <button
          onClick={() =>
            setProcessState((s) => {
              s.stage = 'enteringData'
            })
          }
          className="flex items-center space-x-1 font-medium text-gray-400 hover:underline"
        >
          <ArrowNarrowLeftIcon className="w-3.5 h-3.5" />
          <span>{t`go_back`}</span>
        </button>
        <h2 className="mt-4 text-xl font-medium text-gray-900">{t`registration.enter_code`}</h2>
        <p className="mt-2 text-sm text-gray-700">
          {processState.codeType === 'call' &&
            t(`registration.enter_code_explanation_call`, { phone: processState.phone })}
          {processState.codeType === 'sms' &&
            t(`registration.enter_code_explanation_sms`, { phone: processState.phone })}
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onCodeSubmit)}
        className="grid grid-cols-1 gap-x-6 gap-y-8 mt-10 sm:grid-cols-2"
      >
        <div className="flex col-span-2 items-center space-x-4">
          <FormItem
            className=""
            label={t`registration.confirmation_code`}
            error={errors.code?.message}
          >
            <Input
              autoFocus
              {...codeInputProps}
              onChange={(event) => {
                if (!event.target.value.at(-1)?.match(/\d/)) {
                  event.target.value = event.target.value.slice(0, -1)
                  return
                }
                onChange(event)
                if (event.target.value.length === 4) {
                  handleSubmit(onCodeSubmit)()
                }
              }}
              disabled={submitting}
              hasError={Boolean(errors.code?.message)}
              inputMode="numeric"
              autoComplete="one-time-code"
              className="w-48"
            />
          </FormItem>
          {submitting && <Spinner className="!w-6 !h-6 mt-6" />}
          {success && <CheckIcon className="!w-6 !h-6 mt-6 text-green-600" />}
        </div>
      </form>
      <Button
        theme="primary"
        disabled={retryButtonTimeout > 0} // || (!recaptchaLoaded && type === 'signup')}
        className="mt-4"
        onClick={async () => {
          setRetryButtonUsed(true)
          if (processState.type === 'signup') {
            // if (!recaptchaLoaded) return
            // const recaptchaToken = await executeRecaptcha('send_again')
            const response = await ctx.registerSendAuthCode(processState.phone)

            match(response)
              .with({ status: 'AuthCodeSent' }, () => {
                setRetryButtonTimeout(60)
                resetField('code')
              })
              .with({ status: 'AttemptsLimitReached' }, (response) => {
                setError('code', {
                  type: 'validate',
                  message:
                    t`login.attempts_limit_reached` +
                    ` ${response.seconds} ` +
                    t('second_accusative', { count: response.seconds }),
                })
              })
              .with({ status: 'CaptchaFailed' }, () => {
                setError('code', { type: 'validate', message: t`something_went_wrong` })
              })
              .exhaustive()
          }
          if (processState.type === 'login') {
            const payload = processState.data! as LoginFormData
            const response = await ctx.logIn(payload)

            match(response)
              .with({ status: 'AuthNeedTwoFactor' }, () => {
                setRetryButtonTimeout(60)
                resetField('code')
              })
              .with({ status: 'AttemptsLimitReached' }, (response) => {
                setError('code', {
                  type: 'validate',
                  message:
                    t`login.attempts_limit_reached` +
                    ` ${response.seconds} ` +
                    t('second_accusative', { count: response.seconds }),
                })
              })
              .otherwise(() => {
                setError('code', {
                  type: 'validate',
                  message: t`registration.something_went_wrong`,
                })
              })
          }
          if (processState.type === 'recovery') {
            const payload = processState.data! as RecoveryFormData
            const response = await ctx.recoverySendAuthCode(payload.phone)

            match(response)
              .with({ status: 'AuthCodeSent' }, () => {
                setRetryButtonTimeout(60)
                resetField('code')
              })
              .with({ status: 'UserNotFound' }, () => {
                setError('code', { type: 'validate', message: t`login.user_not_found` })
              })
              .with({ status: 'AttemptsLimitReached' }, (response) => {
                setError('code', {
                  type: 'validate',
                  message:
                    t`login.attempts_limit_reached` +
                    ` ${response.seconds} ` +
                    t('second_accusative', { count: response.seconds }),
                })
              })
              .exhaustive()
          }
        }}
      >
        {processState.codeType === 'call' && t`registration.call_again`}{' '}
        {processState.codeType === 'sms' && t`registration.send_again`}{' '}
        {retryButtonTimeout > 0
          ? retryButtonTimeout === 60
            ? '1:00'
            : `0:${retryButtonTimeout.toString().padStart(2, '0')}`
          : ''}
      </Button>
      <div className="mt-2 text-sm text-yellow-600 whitespace-pre">
        {retryButtonUsed ? t`login.auth_code_long_wait` : ' '}
      </div>
    </>
  )
}

export default ConfirmationCodeForm
